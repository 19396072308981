(function($) {

	let modals = $('.modal');

	$(modals).on('hide.bs.modal', function () {

		let videos = $(this).find('video');

		if(videos.length) {

			$(videos).each(function() {
				$(this).trigger('pause');
			});

		}

	});

})( jQuery );
