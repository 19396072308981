require('bootstrap');
require('./offcanvas');
require('./smooth-scroll');
require('./navbar-shrink');

require('./rellax');
require('./slideshow');
require('./aos');

require('./modal');

require('./watch-for-hover');
require('./browser-sync');

