import Swiper from 'swiper/dist/js/swiper.min.js';

(function($) {

	if($('.news-slideshow').length) {

		$('.news-slideshow').each(function() {

			let id = $(this).attr('id');
			let newsSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: false,
				speed: 500,
				spaceBetween: 0,
				slidesPerView: 3,
				watchOverflow: true,
				autoplay: false,
				simulateTouch: false,
				breakpoints: {
					992: {
						slidesPerView: 1
					}
				},

				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}

			});

		});

	}

	if($('.accordion-slideshow').length) {

		$('.accordion-slideshow').each(function() {

			let id = $(this).attr('id');
			let accordionSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: true,
				speed: 500,
				spaceBetween: 15,
				slidesPerView: 'auto',
				centeredSlides: true,
				autoplay: false,
				simulateTouch: false,

				pagination: {
					el: '.swiper-pagination',
					type: 'fraction'
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}

			});

			$('#accordion-heading-' + id.substring(id.length - (id.length - 20), id.length)).click(function() {

				if($(this).hasClass('collapsed')) {
					setTimeout(() => {
						accordionSwiper.update();
					}, 1);
				}

			});

		});

	}

})( jQuery );
