(function($) {

	let body = $('body');
	let navbar = $('.navbar-shrink');



	$(document).scroll(onScroll);



	function onScroll() {

		if($(document).scrollTop() > 100) {
			$(navbar).addClass('shrinked');
			$(body).addClass('body-shrinked');
		}
		else {
			$(navbar).removeClass('shrinked');
			$(body).removeClass('body-shrinked');
		}

	}

})( jQuery );
