import Rellax from 'rellax';

(function($) {

	let oldWidth = $(window).width();
	let currentWidth = $(window).width();


	if($('.image-module-parallax').length) {

		let rellax = new Rellax('.image-module-parallax', getOptions());

		$(window).resize(function() {

			currentWidth = $(window).width();

			if((currentWidth >= 768 && oldWidth < 768) || (currentWidth < 768 && oldWidth >= 768)) {
				console.log('Refresh');
				rellax.destroy();
				rellax = new Rellax('.image-module-parallax', getOptions());
			}

			oldWidth = $(window).width();

		});

	}



	function getOptions() {

		let options = {
			center: true,
			zindex: 1
		};

		if($(window).width() >= 768) {
			options.speed = -6;
		}
		else {
			options.speed = -1;
		}

		return options;

	}

})( jQuery );
