(function($) {

	$('.scrollToTop').click(scrollToTop);
	$('a[href^="/#"]').click(scrollTo);



	// Methods
	function scrollToTop() {
		$('html, body').animate({ scrollTop: 0 }, 600);
		return false;
	}



	function scrollTo(event) {

		let attr = $.attr(this, 'href');

		try {

			event.preventDefault();

			$('html, body').animate({
				scrollTop: $(attr.substring(1, attr.length)).offset().top
			}, 500);

		} catch(e) {
			$(location).attr('href', attr);
		}


	}

})( jQuery );
